/***************************************************************************************************
* COMPONENTS AND THEMES
*/

/*
* Angular Material, material design components
* see https://material.angular.io
*/
@import 'libs/shared/ui/styles/src/lib/scss/m3-theme';
engineering-app {
  /*
  * Fix for the cutoff form input labels when embedded in medias
  */
  label[matformfieldfloatinglabel] {
    font-size: 16px !important;
  }

  .cdk-overlay-container {
    z-index: 99;
  }
}

/***************************************************************************************************
  * UTILITIES
  */

/*
  * TailwindCSS, utility-first css framework
  * see https://tailwindcss.com/docs
  */
@tailwind base;

/*
 *  Officially nesting layers like this is not suported by tailwind and results in an error upon generation of the CSS files by PostCSS
 *  As of now, inspecting the source and testing the UI, this officially unsupported syntax hasn't caused any issues (yet).
 *  The prefix is required to prevent tailwind styles leaking into the global scope of the embedding site. If this behavior would cause
 *  problems down the road, we cannot simply remove the prefix
 *
 *  @see https://github.com/tailwindlabs/tailwindcss/issues/5521
 * ========
 */
engineering-app {
  @tailwind components;
}
@tailwind utilities;

/*
 * Tailwind preflight modified with application scope only
 */
@import './preflight';

engineering-app {
  /** fix font size for medias integration */
  @apply text-body-large #{!important};

  @layer resets {
    * {
      font-family:
        Noto Sans,
        sans-serif !important;
    }

    .material-icons {
      font-family: 'Material Icons' !important;
    }
  }

  .mat-button-base.mat-button .mat-button-focus-overlay {
    @apply hidden;
  }

  button {
    outline: none !important;
  }

  // apply full width to wrapper so it actually takes the full width
  mat-form-field.w-full {
    .mat-mdc-text-field-wrapper {
      width: 100%;
      min-width: 100%;
    }
    .mat-mdc-form-field-infix {
      width: 100%;
    }
  }

  // animation for certain dialogs (e.g. result report)
  @keyframes upslide {
    0% {
      @apply mt-[30vh] opacity-0;
    }
    100% {
      @apply mt-8 opacity-100;
    }
  }

  .dialog-upwards-animation {
    @apply w-[90vw] min-w-[90vw] h-[100vh] min-h-[100vh] #{!important};
    @apply mt-8;
    animation: upslide 0.25s forwards;

    mat-dialog-container {
      @apply overflow-y-hidden rounded-b-none #{!important};
    }

    mat-dialog-content {
      // the following includes the height of the user survey box.
      // remove 58px-3rem if you remove the box
      @apply max-h-none h-[calc(100%-170px-100px-3rem)]  sm:h-[calc(100%-170px-58px-3rem)];
    }
  }

  #result-report {
    max-width: 90vw;
    min-height: 100svh;
    @apply flex flex-col-reverse outline-none;
    justify-content: start;
    animation: upslide 0.25s forwards;
    .dialog-parent {
      background: #fff;
      min-height: 95svh;
      max-height: 95svh;
      @apply px-3 sm:px-6 pt-3 sm:pt-6 rounded-md shadow-lg flex flex-col justify-start;

      .dialog-title {
        @apply flex-shrink-0 flex-grow-0;
      }

      .dialog-body {
        max-height: calc(82svh - 24px);
        height: calc(82svh - 24px);
        @apply overflow-y-auto;
      }
    }
  }

  .mat-mdc-dialog-title {
    @apply py-0;
  }

  .mat-mdc-dialog-title::before {
    @apply h-0;
  }

  //checkbox
  .mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-selected-hover-state-layer-color: none;
    --mdc-checkbox-unselected-hover-state-layer-color: none;
  }

  //radio button overrides
  .mat-mdc-radio-button.mat-primary {
    --mat-radio-checked-ripple-color: none;
  }

  .mat-mdc-radio-button {
    @apply text-title-small;

    .mdc-radio {
      transform: scale(0.7);
      @apply pr-1;
    }

    .mdc-form-field {
      height: 24px;
      @apply pr-2;
    }

    // green background
    &.mat-mdc-radio-checked {
      .mdc-form-field {
        @apply rounded-full;
        @apply bg-secondary-legacy-50;
      }

      .mdc-label {
        @apply text-primary #{!important};
      }
    }

    &:not(.mat-mdc-radio-checked) {
      @apply text-medium-emphasis;
    }

    // suboption
    &.suboption {
      @apply rounded-full;

      .mdc-label {
        @apply text-[8px];
        @apply whitespace-nowrap;
      }

      &:not(.mat-mdc-radio-checked) {
        @apply bg-secondary-variant;
        @apply pb-1;
      }
    }
  }

  /*
  weird border issue Angular material with Tailwind https://github.com/tailwindlabs/tailwindcss/discussions/9993
  in preflight style of tailwinds sets border-style to solid, other way to fix it, would be to adjust
  preflight, which can case other issues for tailwind
  */
  .mdc-notched-outline__notch {
    border-right: none !important;
  }

  // helper to avoid the overlay being cropped by the notch on ios devices
  .preview-mobile {
    bottom: env(safe-area-inset-bottom);
  }
  .mobile-report {
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
  }
  .mobile-report #result-report {
    min-height: auto;
  }

  // devices with top notch iOS and newer Android devices
  .mat-drawer-inner-container {
    padding-top: env(safe-area-inset-top);
  }

  .schaeffler-header-logo {
    margin-right: env(safe-area-inset-right);
  }
}

schaeffler-app-shell > mat-sidenav-container > mat-sidenav-content {
  background-color: white;
}

.blur-backdrop {
  width: 100%;
  @apply backdrop-blur-md left-0 right-0 -ml-4 fixed md:block bottom-0 h-56 hidden pointer-events-none scale-y-100 transition-transform ease-out duration-150 origin-bottom;
  mask: linear-gradient(transparent, rgba(0, 0, 0, 0.9) 20%, black);
  z-index: 1;
}

#legal-pages {
  @apply prose max-w-2xl prose-p:text-high-emphasis prose-h5:font-semibold;
}

// hide underneath scrollbar in medias once dialog is open
.cdk-global-scrollblock body {
  overflow: hidden;
}

// helper to show always the scrollbar in the result preview
.active-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
}

.active-scrollbar::-webkit-scrollbar:vertical {
  width: 12px;
}

.active-scrollbar::-webkit-scrollbar:horizontal {
  height: 5px;
}

.active-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.active-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
}
